@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

/* *************Global Antd design ************/

/* antd calendar classes start */
.ant-picker-calendar-header {
  margin-bottom: 1rem;
}

.ant-select-selector {
  height: 40px !important;
  /* width: 100px !important; */
  border: none !important;
  background: #612c41 !important;
  color: #fff !important;
}

.ant-select .ant-select-arrow {
  margin-top: 0;
  font-size: 1rem;
  display: block;
  color: #fff;
}

.ant-picker-calendar .ant-picker-cell {
  padding: 1rem 0;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.calendar thead {
  background: #612c41;
  height: 60px;
}

.calendar thead tr th {
  color: #fff !important;
  font-size: 1rem;
}

.calendar .ant-picker-cell-selected {
  background: #612c41 !important;
}
.calendar .ant-picker-cell-selected .ant-picker-calendar-date {
  background: #612c41 !important;
}

.calendar.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: none;
}

.calendar {
  height: 100%;
}
/* antd calendar classes end */

.ant-drawer-body {
  background: #27202298;
}

.ant-drawer-close {
  color: #fff !important;
}

.ant-drawer-content {
  background: #272022b8 !important;
}

.ant-drawer-content-wrapper {
  width: 250px !important;
}

.ant-drawer-header {
  background: #272022ae;
}

.modal_back {
  background: linear-gradient(#bd2e92, #5a1b34e2);
}

/* *************scroller design ************/
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#bd2e92, #6b4ba0);
  border-radius: 50%;
}

/* ***************Loader classes**************** */
.loader_div {
  background: linear-gradient(#5a1b34e2, #000000e7);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ***************utility classes**************** */
.header_container {
  width: 90%;
  margin: auto;
  animation: fadeIn 1s ease-in-out;
}

.common_container {
  width: 90%;
  margin: auto;
  animation: slideInFromTop 1s ease-in-out;
}

.under_line {
  text-decoration: underline;
}

.right_border {
  border-right: 1px solid #fff;
}

/* ***************font Utility classes**************** */
.big_font {
  font: 800 3rem/3.2rem "Open Sans", sans-serif;
  color: #fff;
}

.medium_font {
  font: 700 1.2rem "Open Sans", sans-serif;
  color: #fff;
}

.small_font {
  font: 500 0.9rem "Open Sans", sans-serif;
  color: #fff;
}

.title_font {
  font: 800 1.6rem "Open Sans", sans-serif;
  color: #fff;
}

/* ****************Header styling**************** */
.logo_width {
  width: 18%;
}

.ant-menu-submenu-popup.ant-menu-submenu {
  background: #fff;
  padding: 0%;
}

.header_div {
  background: #272022ae;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  z-index: 1;
}

.header_anchor > a {
  text-decoration: none;
  color: #fff;
  padding: 0 1.5rem;
  font: 600 0.9rem Open Sans, sans-serif;
}

.mobile_header_anchor > a {
  text-decoration: none;
  color: #fff;
  font: 600 0.9rem Open Sans, sans-serif;
  display: block;
  margin: 2rem 0;
  text-align: center;
}

.header_logo{
  width: 50%;
}

/* ****************Home styling**************** */
.home_back {
  background: url("./assets/back2.png");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  text-align: center !important;
  padding: 11rem 0 0rem 0 !important;
}

.play_btn {
  cursor: pointer;
  border: 1px solid #fff;
  padding: 1rem;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon_size {
  font-size: 50px;
}

.video_class > video{
  width: 100%;
  height: 650px;
}

.store_img > a > img {
  margin: 0 1.3rem !important;
  width: 15%;
}

/* ****************About styling**************** */
.about_div {
  background: #733753a3;
  padding: 2.5rem;
  min-height: 50vh;
}

/* ****************Contact styling**************** */
.contact_div {
  background: #612c41;
  padding: 2.5rem 5rem 1rem 5rem;
}

.contact_team_div {
  background: #733753ad;
}

.contact_input {
  height: 40px;
  background: transparent !important;
  border-color: #fff !important;
  color: #fff;
  border-radius: 0%;
}

.text_area_input {
  min-height: 100px !important;
}

.contact_input::placeholder {
  color: #fff;
}

.contact_btn {
  background: #fff;
  font: 650 0.9rem Open Sans, sans-serif;
  height: 40px;
  border-radius: 0%;
}

/* ****************Photo Gallery styling**************** */
.image_manage {
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.thumbnail_manage {
  height: 137px;
  width: 100%;
}

.main_slider_btn {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 2rem;
}

.slider_btn {
  padding: 0.5rem;
  background: #fff;
  border: none;
  display: flex;
  align-items: center;
}

/* ****************Events styling**************** */
.position_dot {
  position: absolute;
  left: 38%;
  bottom: -84%;
}

.events_detail {
  background-color: #612c41;
  box-shadow: 0 3px 8px #00000024;
  padding: 10px;
  height: 100%;
}

.img_content {
  background: linear-gradient(to bottom, #1f1f1f3a, #1f1f1fd0),
    url("./assets/back.png") center no-repeat;
  padding: 0.5rem;
  text-align: center;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 10px;
  height: 100px;
  margin-top: 0.5rem;
}

.img_content > dt {
  color: #fff;
}

.event_btn {
  /* background: #612c41; */
  font: 600 0.9rem "Open Sans", sans-serif;
  color: #fff;
  /* padding: 0.3rem 2rem; */
  /* border-radius: 5px; */
}

/* ****************Footer styling**************** */
.footer_div {
  padding: 1.5rem 0;
  background: #272022ae;
  text-align: start;
  margin-top: 3rem;
}

.table th, .table td {
  padding: 0px !important; 
  background: transparent !important;
}

/* Remove border from table, rows, and cells */
.table, .table th, .table td {
  border: none;
}


/* ****************animation classes************ */
/* Animation for header container */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animation for main container */
@keyframes slideInFromTop {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
