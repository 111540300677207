@media screen and (min-width: 1700px) {
  .home_back {
    padding: 16rem 0 0rem 0 !important;
  }
  .header_anchor > a {
    font: 600 1.2rem Open Sans, sans-serif;
  }
  .big_font {
    font: 800 3.5rem/4rem "Open Sans", sans-serif;
    color: #fff;
  }
  .medium_font {
    font: 700 1.4rem "Open Sans", sans-serif;
  }
  .small_font {
    font: 700 1.3rem "Open Sans", sans-serif;
  }
  .image_manage {
    height: 650px;
    border-radius: 5px;
  }
  .thumbnail_manage {
    height: 250px;
  }
  .contact_input {
    height: 60px;
  }
  .text_area_input {
    min-height: 250px !important;
  }
  .contact_input::placeholder {
    font-size: 1rem;
  }
  .contact_btn {
    height: 60px;
    font-size: 1.1rem;
  }
  .icon_size {
    font-size: 75px;
  }
  .play_btn {
    padding: 1.5rem;
  }
  .img_content {
    height: 140px;
  }
}

@media screen and (max-width: 992px) {
  .home_back {
    padding: 10rem 0 0rem 0 !important;
  }
  .store_img > a > img {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .logo_width {
    width: 25%;
  }
  .about_div {
    padding: 1.5rem;
  }
  .contact_div {
    padding: 2.5rem 2.5rem 1rem 2.5rem;
  }
  .image_manage {
    height: 450px;
  }
  .video_class > video{
    width: 100%;
    height: auto;
  }
  .store_img > a > img {
    width: 27%;
  }
}

@media screen and (max-width: 575px) {
  .right_border {
    border: none;
  }
  .logo_width {
    width: 45%;
  }
  .home_back {
    padding: 9rem 0 0rem 0 !important;
  }
  .big_font {
    font: 800 2rem/2.5rem "Open Sans", sans-serif;
    color: #fff;
  }
  .about_div {
    padding: 1rem;
  }
  .contact_div {
    padding: 1rem 1rem 1rem 1rem;
  }
  .image_manage {
    height: 300px;
  }
  .main_slider_btn {
    padding: 0 1rem;
  }
  .play_btn {
    padding: 0.5rem;
  }
  .icon_size {
    font-size: 25px;
  }
  .img_content {
    height: 200px;
  }
  .store_img > a > img {
    margin: 0 0.5rem !important;
    width: 40%;
  }
}
